import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_PAYMENT_TRANSACTIONS, params),
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            status: params.status || undefined,
            userPaymentMethodId: params.userPaymentMethodId || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_PAYMENT_TRANSACTION, params),
        params,
    }));
};

export const verify = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.VERIFY,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_PAYMENT_TRANSACTION_VERIFY, params),
        params,
    }));
};

export const status = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.STATUS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_PAYMENT_TRANSACTION_STATUS, params),
        params,
        requestParams: params,
    }));
};