import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_TRANSACTION_STATUS_CANCELED } from 'Consts/paymentTransactions';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getStatusLabel } from 'Utils/paymentTransaction';
import { getFormattedDate } from 'Utils/date';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import PaymentTransactionStatusModal from 'Components/adept/modals/PaymentTransactionStatus';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class AdeptPaymentTransactionsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        paymentTransactions: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    state = {
        showPaymentTransactionStatus:false,
        statusModalData:null,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;

        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(predefinedQuery) !== JSON.stringify(prevProps.predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    showStatusModal = element => {
        const { actions } = this.props;

        this.setState({ showPaymentTransactionStatus: true }, () => {
            actions.status({ id: element.id })
                .then(data => {
                    const { paymentTransactionStatus } = data.payload;

                    this.setState({ statusModalData: paymentTransactionStatus });
                });
        });
    }

    render() {
        const { 
            paymentTransactions,
            location, 
            history,
        } = this.props;
        const { showPaymentTransactionStatus, statusModalData } = this.state;

        return (
            <StyledComponent
                className="adept-payment-transactions-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={paymentTransactions}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={formatPrice(element.value) + `(${getFormattedDate(element.createdAt, 'date')})`}
                            subtitle={element.userOffer.name || element.userOffer.offer.name}
                            image={{
                                visible: true,
                                url: require('Theme/images/icons/calendar.svg'),
                            }}
                            additionalPosition='space-evenly-max'
                            additionals={[{
                                name: 'Status płatności',
                                value: [{
                                    isVisible: true,
                                    label: getStatusLabel(element).label,
                                    state: getStatusLabel(element).stateColor,
                                }],
                                type: 'labels',
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Pobierz  fakturę',
                                visible: Boolean(element.invoice && element.invoice.url),
                                style: 'gradient',
                                href: element.invoice && element.invoice.url,
                                target: '_blank',
                            }, {
                                visible: element.status == PAYMENT_TRANSACTION_STATUS_CANCELED,
                                type: 'button',
                                label: 'Status płatności',
                                style: 'gradient',
                                onClick: () => this.showStatusModal(element),
                            }]}
                        />
                    )}
                />
                <PaymentTransactionStatusModal
                    isOpen={showPaymentTransactionStatus}
                    location={location}
                    history={history}
                    data={statusModalData}
                />
            </StyledComponent>
        );
    }
}
