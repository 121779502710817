import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADEPT_MODAL_PAYMENT_METOD_CREATE } from 'Consts/routes';
import { PAYMENT_TRANSACTION_STATUS_COMPLETED } from 'Consts/paymentTransactions';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import UserPaymentMethodsList from 'Components/adept/userPaymentMethods/List';
import PaymentTransactionsList from 'Components/adept/paymentTransactions/List';

export default class AdeptFinances extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};
    state = {};

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="adept-finances"
                styles={require('./styles')}
            >
                <Page>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Finanse"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === 'paymentMethods'),
                            key: 'createPaymentMethod',
                            label: 'Nowa metoda płatności',
                            style: 'gradient',
                            layout: 'wide',
                            onClick: () => history.push(
                                withVariables(
                                    location.pathname,
                                    {},
                                    {},
                                    ADEPT_MODAL_PAYMENT_METOD_CREATE
                                )
                            ),
                        }]}
                        tabs={[{
                            key: 'paymentMethods',
                            label: 'Metody płatności',
                            children: (
                                <UserPaymentMethodsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: 'paymentTransactions',
                            label: 'Rozliczenia',
                            children: (
                                <PaymentTransactionsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </Page>
            </StyledComponent>
        );
    }
}

