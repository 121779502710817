import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ADEPT_USER_PAYMENT_METHOD } from 'Consts/routes';
import { USER_PAYMENT_METHOD_CARD_TOKEN_TYPE_RECURRING } from 'Consts/usersPaymentMethods';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class AdeptUserPaymentMethodsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            setAsDefault: PropTypes.func.isRequired,
            setEnabledStatus: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
        }).isRequired,
        predefinedQuery: PropTypes.object,
        userPaymentMethods: PropTypes.object.isRequired,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;
        
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(predefinedQuery) !== JSON.stringify(prevProps.predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    onRemove = element => {
        const { location, history, actions } = this.props;

        return actions.remove({ id: element.id })
            .then(() => {
                history.push(
                    withVariables(
                        location.pathname,
                        {},
                        { ...parseQueryToObject(location.search), timestamp: Date.now() }
                    )
                );
            })
            .catch(error => {
                Object.keys(error.payload.validationErrors).map(fieldName => {                       
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(errorMessage);                        
                    });
                });

                if (Object.keys(error.payload.validationErrors).length == 0) {
                    toast.error('Wystąpił błąd, spróbuj ponownie później');
                }
            });
    }

    render() {
        const { userPaymentMethods, location, history, actions } = this.props;

        return (
            <StyledComponent
                className="adept-user-payment-methods-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userPaymentMethods}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={`Karta płatnicza: ${element.maskedCard}`}
                            subtitle={element.cardExpirationMonth && element.cardExpirationYear && `Data ważności: ${element.cardExpirationMonth}.${element.cardExpirationYear}`}
                            disabled={Boolean(!element.enabled)}
                            image={{
                                visible: true,
                                url: require('Theme/images/icons/paymentMethods.svg'),
                            }}
                            additionals={[{
                                name: 'Status karty',
                                value: [{
                                    isVisible: element.confirmed !== true,
                                    label: 'Niepotwierdzona',
                                    state: 'alert',
                                }, {
                                    isVisible: element.confirmed == true,
                                    label: 'Potwierdzona',
                                    state: 'success',
                                }, {
                                    isVisible: element.enabled == true,
                                    label: 'Włączona',
                                    state: 'success',
                                }, {
                                    isVisible: element.enabled !== true,
                                    label: 'Wyłączona',
                                    state: 'alert',
                                }, {
                                    isVisible: element.default == true,
                                    label: 'Domyślna',
                                    state: 'success',
                                }],
                                type: 'labels',
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Wyłącz',
                                visible: userPaymentMethods.elements.length > 1 && Boolean(element.enabled),
                                onClick: () => actions.setEnabledStatus({ id: element.id, enabled: false }),
                                style: 'gradient',
                            }, {
                                type: 'button',
                                label: 'Włącz',
                                visible: Boolean(!element.enabled),
                                onClick: () => actions.setEnabledStatus({ id: element.id, enabled: true }),
                                style: 'gradient',
                            }, {
                                type: 'button',
                                label: 'Ustaw jako domyślna',
                                visible: Boolean(!element.default),
                                onClick: () => {
                                    actions.setAsDefault({ id: element.id })
                                        .then(this.loadData);
                                },
                                style: 'gradient',
                            }, {
                                type: 'button',
                                label: 'Usuń',
                                visible: userPaymentMethods.elements.length > 1 && Boolean(!element.enabled),
                                onClick: () => this.onRemove(element),
                                style: 'gradient',
                            }, {
                                type: 'button',
                                label: 'Szczegóły',
                                visible: true,
                                onClick: () => history.push(
                                    withVariables(ADEPT_USER_PAYMENT_METHOD.path, { id: element.id })
                                ),
                                style: 'gradient',
                            }]}
                            controlsType='multiselect'
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}
