import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .admin-paginated-list-element-v2 {
            .wrapper {
                .block-left {
                    .title-block {
                        .title-content {
                            .subtitle {
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
    `;
