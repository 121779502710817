import { connect } from 'react-redux';
import Component from './component';

import {
    list,
    setAsDefault,
    destroy,
    setEnabledStatus,
} from 'Redux/modules/adept/userPaymentMethods/actions';

export default connect(
    state => ({
        userPaymentMethods: state.adeptUserPaymentMethods.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            setAsDefault: dispatch(setAsDefault),
            remove: dispatch(destroy),
            setEnabledStatus: dispatch(setEnabledStatus),
        },
    })
)(Component);
