import { connect } from 'react-redux';
import Component from './component';

import {
    list,
    status,
} from 'Redux/modules/adept/paymentTransactions/actions';

export default connect(
    state => ({
        paymentTransactions: state.adeptPaymentTransactions.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            status: dispatch(status),
        },
    })
)(Component);
